import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: appConfig.title + " › Home",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        title: appConfig.title + " › Login",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Entrando",
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        title: appConfig.title + " › Entrando",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Sair",
      path: "/logout",
      redirect: "/login",
    },
    {
      name: "Cadastre-se",
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title + " › Cadastre-se",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Cadastre-se › Patrocinador",
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title + " › Cadastre-se › Patrocinador",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Esqueceu sua senha?",
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        title: appConfig.title + " › Esqueceu sua senha?",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Alterar senha",
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        title: appConfig.title + " › Alterar senha",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Upgrade",
      path: "/upgrade",
      component: () => import("./views/upgrade"),
      meta: {
        title: appConfig.title + " › Upgrade",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Minha Conta",
      path: "/account",
      component: () => import("./views/account/account"),
      meta: {
        title: appConfig.title + " › Minha Conta",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Meus Documentos",
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: {
        title: appConfig.title + " › Meus Documentos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Meus Endereços",
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: {
        title: appConfig.title + " › Meus Endereços",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Minha Senha",
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        title: appConfig.title + " › Minha Senha",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Senha de Segurança",
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: {
        title: appConfig.title + " › Senha de Segurança",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Senha de Segurança › Recuperar",
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: {
        title: appConfig.title + " › Senha de Segurança › Recuperar",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Meu PIX",
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: appConfig.title + " › Meu PIX",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Contratos › Lista",
      path: "/contracts",
      component: () => import("./views/contracts/list"),
      meta: {
        title: appConfig.title + " › Contratos › Lista",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Contratos › Ver",
      path: "/contracts/:id",
      component: () => import("./views/contracts/view"),
      meta: {
        title: appConfig.title + " › Contratos › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Carteira › Extrato",
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract-network"),
      meta: {
        title: appConfig.title + " › Carteira › Extrato",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Carteira › Transferir",
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: {
        title: appConfig.title + " › Carteira › Transferir",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Carteira › Pagar",
      path: "/wallet/pay",
      component: () => import("./views/wallet/pay"),
      meta: {
        title: appConfig.title + " › Carteira › Pagar",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Carteira › Pagar › Pedido",
      path: "/wallet/pay/:id",
      component: () => import("./views/wallet/pay"),
      meta: {
        title: appConfig.title + " › Carteira › Pagar › Pedido",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Carteira › Saque",
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: {
        title: appConfig.title + " › Carteira › Saque",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Carteira › Saque › Histórico",
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: {
        title: appConfig.title + " › Carteira › Saque › Histórico",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Rede › Clientes",
      path: "/network/clients",
      component: () => import("./views/network/clients"),
      meta: {
        title: appConfig.title + " › Rede › Clientes",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Rede › Indicados",
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        title: appConfig.title + " › Rede › Indicados",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Rede › Equipe",
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        title: appConfig.title + " › Rede › Equipe",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Network › Matriz",
      path: "/network/matriz",
      component: () => import("./views/network/matriz"),
      meta: {
        title: appConfig.title + " › Network › Matriz",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Network › Matriz › Downline",
      path: "/network/matriz/:id",
      component: () => import("./views/network/matriz"),
      meta: {
        title: appConfig.title + " › Network › Matriz › Downline",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Rede › Unilevel",
      path: "/network/unilevel",
      component: () => import("./views/network/unilevel"),
      meta: {
        title: appConfig.title + " › Rede › Unilevel",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Rede › Unilevel › Indicados",
      path: "/network/unilevel/:id",
      component: () => import("./views/network/unilevel"),
      meta: {
        title: appConfig.title + " › Rede › Unilevel › Indicados",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Relatórios › Indicação",
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: {
        title: appConfig.title + " › Relatórios › Indicação",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Relatórios › Compras",
      path: "/reports/compras",
      component: () => import("./views/reports/compras"),
      meta: {
        title: appConfig.title + " › Relatórios › Compras",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Relatórios › E-commerce",
      path: "/reports/ecommerce",
      component: () => import("./views/reports/ecommerce"),
      meta: {
        title: appConfig.title + " › Relatórios › E-commerce",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Relatórios › Graduação",
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: {
        title: appConfig.title + " › Relatórios › Graduação",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja",
      path: "/store",
      redirect: "/store/featured",
    },
    {
      name: "Loja › Destaques",
      path: "/store/featured",
      component: () => import("./views/store/featured"),
      meta: {
        title: appConfig.title + " › Loja › Destaques",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja › Categoria",
      path: "/store/category/:id",
      component: () => import("./views/store/category"),
      meta: {
        title: appConfig.title + " › Loja › Categoria",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja › Buscar",
      path: "/store/search",
      component: () => import("./views/store/search"),
      meta: {
        title: appConfig.title + " › Loja › Buscar",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja › Carrinho",
      path: "/store/cart",
      component: () => import("./views/store/cart"),
      meta: {
        title: appConfig.title + " › Loja › Carrinho",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja › Pedidos",
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: {
        title: appConfig.title + " › Loja › Pedidos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja › Pedido",
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: {
        title: appConfig.title + " › Loja › Pedido",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Loja › Franquias",
      path: "/store/franchises",
      component: () => import("./views/store/franchises"),
      meta: {
        title: appConfig.title + " › Loja › Franquias",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Downloads",
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        title: appConfig.title + " › Downloads",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        title: appConfig.title + " › FAQ",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Tickets",
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        title: appConfig.title + " › Tickets",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Tickets › Ver",
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        title: appConfig.title + " › Tickets › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV",
      path: "/franchise",
      component: () => import("./views/franchise/home"),
      meta: {
        title: appConfig.title + " › PDV",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Cadastrar",
      path: "/franchise/register",
      component: () => import("./views/franchise/register"),
      meta: {
        title: appConfig.title + " › PDV › Cadastrar",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Meu PDV",
      path: "/franchise/account",
      component: () => import("./views/franchise/account"),
      meta: {
        title: appConfig.title + " › PDV › Meu PDV",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Relatórios",
      path: "/franchise/reports",
      component: () => import("./views/franchise/reports"),
      meta: {
        title: appConfig.title + " › PDV › Relatórios",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pedidos",
      path: "/franchise/orders",
      component: () => import("./views/franchise/orders"),
      meta: {
        title: appConfig.title + " › PDV › Pedidos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pedidos › Ver",
      path: "/franchise/orders/:id",
      component: () => import("./views/franchise/order"),
      meta: {
        title: appConfig.title + " › PDV › Pedidos › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pedidos › Lançar",
      path: "/franchise/store/cart",
      component: () => import("./views/franchise/store-cart"),
      meta: {
        title: appConfig.title + " › PDV › Pontos › Lançar",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pontos › Extrato",
      path: "/franchise/points/extract",
      component: () => import("./views/franchise/points-extract"),
      meta: {
        title: appConfig.title + " › PDV › Pontos › Extrato",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pontos › Pedidos",
      path: "/franchise/points/orders",
      component: () => import("./views/franchise/points-orders"),
      meta: {
        title: appConfig.title + " › PDV › Pontos › Pedidos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pontos › Pedidos › Ver",
      path: "/franchise/points/orders/:id",
      component: () => import("./views/franchise/points-order"),
      meta: {
        title: appConfig.title + " › PDV › Pontos › Pedidos › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Pontos › Lançar",
      path: "/franchise/points/cart",
      component: () => import("./views/franchise/points-cart"),
      meta: {
        title: appConfig.title + " › PDV › Pontos › Lançar",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Estoque",
      path: "/franchise/inventory",
      component: () => import("./views/franchise/inventory"),
      meta: {
        title: appConfig.title + " › PDV › Estoque",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Estoque › Reposição",
      path: "/franchise/inventory/restock",
      component: () => import("./views/franchise/inventory-restock"),
      meta: {
        title: appConfig.title + " › PDV › Reposição",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Estoque › Pedidos de Reposição",
      path: "/franchise/inventory/orders",
      component: () => import("./views/franchise/inventory-orders"),
      meta: {
        title: appConfig.title + " › PDV › Pedidos de Reposição",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Estoque › Pedidos de Reposição › Ver",
      path: "/franchise/inventory/orders/:id",
      component: () => import("./views/franchise/inventory-order"),
      meta: {
        title: appConfig.title + " › PDV › Pedidos de Reposição › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Carteira › Extrato",
      path: "/franchise/wallet/extract",
      component: () => import("./views/franchise/wallet-extract"),
      meta: {
        title: appConfig.title + " › PDV › Carteira › Extrato",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Carteira › Transferir",
      path: "/franchise/wallet/transfer",
      component: () => import("./views/franchise/wallet-transfer"),
      meta: {
        title: appConfig.title + " › PDV › Carteira › Transferir",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Carteira › Saque",
      path: "/franchise/wallet/withdrawal",
      component: () => import("./views/franchise/wallet-withdrawal"),
      meta: {
        title: appConfig.title + " › PDV › Carteira › Saque",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "PDV › Carteira › Saque › Histórico",
      path: "/franchise/wallet/withdrawal/history",
      component: () => import("./views/franchise/wallet-withdrawal-history"),
      meta: {
        title: appConfig.title + " › PDV › Carteira › Saque › Histórico",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Cursos",
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: {
        title: appConfig.title + " › Cursos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Cursos › Ver",
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: {
        title: appConfig.title + " › Cursos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Cursos › Ver › Aula",
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: {
        title: appConfig.title + " › Cursos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Investimentos › Tipos",
      path: "/investments/types",
      component: () => import("./views/investments/types-list"),
      meta: {
        title: appConfig.title + " › Investimentos › Tipos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Investimentos › Tipos › Ver",
      path: "/investments/types/:id",
      component: () => import("./views/investments/types-view"),
      meta: {
        title: appConfig.title + " › Investimentos › Tipos › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Investimentos › Meus Contratos",
      path: "/investments/contracts",
      component: () => import("./views/investments/contracts-list"),
      meta: {
        title: appConfig.title + " › Investimentos › Meus Contratos",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Investimentos › Meus Contratos › Ver",
      path: "/investments/contracts/:id",
      component: () => import("./views/investments/contracts-view"),
      meta: {
        title: appConfig.title + " › Investimentos › Meus Contratos › Ver",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "404",
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title + " › 404",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.user.contracts &&
            response.data.user.contracts.length > 0 &&
            to.name != "Contratos › Ver"
          ) {
            response.data.user.contracts.forEach(con => {
              return next("/contracts/" + con.id);
            })
          } else if (
            response.data.order &&
            response.data.order.id &&
            to.name != "Loja › Pedido"
          ) {
            return next("/store/order/" + response.data.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;
